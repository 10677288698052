import axios from "@/plugins/axios";
import { setName } from "@/utils/claim/validClaim";

const state = () => ({
  claim: {},
  companies: [],
  revs: [],
  companiesAbbre: [],
  facilities: [],
  facilitiesCode: [],
  healthProfessionals: [],
  billing_provider: [],
  referred: [],
  service_provider: [],
  patients: [],
  rev_centers: [],
  type_of_services: [],
  place_of_services: [],
  referred_provider_roles: [],
  epsdts: [],
  family_plannings: [],
  diagnosis: [],
  billingCompanies: [],
  listBillingCompanies: [],
  formats: [],
  status: [],
  substatus: [],
  subStatusTable: [],
  fields: [],
  qualifiers: [],
  listPlaceOfServices: [],
  listAdmissionType: [],
  listAdmissionSources: [],
  listPatientStatuses: [],
  listBill: [],
  listDRG: [],
  billClassifications: [],
  listDepartmentResponsibilities: [],
  listInsurancePolicies: [],
  pivoteRequest: null,
  dataAdditional: {
    from: null,
    to: null,
    changeSwitch: false,
    unit: null,
  },
  lastDateServices: "",
  loadInit: {
    status: false,
    format: false,
    billings: false,
    claimServices: false,
    addType: false,
    addSources: false,
    patStatus: false,
    listBill: false,
    listDRG: false,
  },
});

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies;
    state.companiesAbbre = companies.map((company) => {
      company.name = `${company.abbreviation} - ${company.name}`;
      return company;
    });
  },

  setFields(state, fields) {
    state.fields = [];
    state.fields = fields;
  },

  setQualifiers(state, qualifiers) {
    state.qualifiers = qualifiers;
  },

  setFacilities(state, facilities) {
    state.facilities = [];
    state.facilitiesCode = [];

    facilities.map((facility) => {
      state.facilities.push({
        id: facility.id,
        name: facility.name,
      });

      state.facilitiesCode.push({
        id: facility.id,
        name: facility.abreviation
          ? `${facility.abreviation} - ${facility.name}`
          : facility.name,
      });
    });
  },

  setHealthProfessionals(state, healthProfessionals) {
    state.billing_provider = [];
    state.referred = [];
    state.service_provider = [];
    state.referred_provider_roles = [];

    state.billing_provider = healthProfessionals.billing_provider.map((ref) => {
      ref.name =
        ref.npi && ref.npi !== "" ? `${ref.npi} - ${ref.name}` : ref.name;
      return ref;
    });
    state.referred = healthProfessionals.referred.map((ref) => {
      ref.name =
        ref.npi && ref.npi !== "" ? `${ref.npi} - ${ref.name}` : ref.name;
      return ref;
    });
    state.service_provider = healthProfessionals.service_provider.map((ref) => {
      ref.name =
        ref.npi && ref.npi !== "" ? `${ref.npi} - ${ref.name}` : ref.name;
      return ref;
    });
    state.referred_provider_roles =
      healthProfessionals.referred_provider_roles.map((ref) => {
        ref.name =
          ref.code && ref.code !== "" ? `${ref.code} - ${ref.name}` : ref.name;
        return ref;
      });
  },

  setHealthProfessionalsTable(state, healthProfessionals) {
    state.healthProfessionals = [];

    state.healthProfessionals = healthProfessionals.map((health) => {
      health.name =
        health.npi && health.npi !== ""
          ? `${health.npi} - ${health.name}`
          : health.name;
      return health;
    });
  },

  setPatients(state, patients) {
    state.patients = patients;
  },

  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
    state.listBillingCompanies = billingCompanies.map((billing) => {
      billing.name = `${billing.abbreviation} - ${billing.name}`;
      return billing;
    });
  },

  setFormats(state, formats) {
    state.formats = formats;
  },

  setRevCenters(state, rev_centers) {
    state.rev_centers = rev_centers;
  },

  setRevs(state, revs) {
    state.revs = revs;
  },

  setClaimServices(state, claimServices) {
    state.place_of_services = [];
    state.type_of_services = [];
    state.epsdts = [];
    state.family_plannings = [];

    state.place_of_services = claimServices.place_of_services;
    state.type_of_services = claimServices.type_of_services;
    state.epsdts = claimServices.epsdts;
    state.family_plannings = claimServices.family_plannings;
  },

  setDiagnosis(state, diagnosis) {
    state.diagnosis = diagnosis;
  },

  setStatus(state, status) {
    state.status = status;
  },

  setSubStatus(state, substatus) {
    state.substatus = substatus;
  },

  setSubStatusTable(state, subStatusTable) {
    state.subStatusTable = subStatusTable;
  },

  setPlaceOfServices(state, listPlaceOfServices) {
    state.listPlaceOfServices = [];
    state.listPlaceOfServices = listPlaceOfServices;
  },

  setListAdmissionType(state, listAdmissionType) {
    state.listAdmissionType = [];
    state.listAdmissionType = listAdmissionType;
  },

  setListAdmissionSources(state, listAdmissionSources) {
    state.listAdmissionSources = [];
    state.listAdmissionSources = listAdmissionSources;
  },

  setListPatientStatuses(state, listPatientStatuses) {
    state.listPatientStatuses = [];
    state.listPatientStatuses = listPatientStatuses;
  },

  setListBill(state, listBill) {
    state.listBill = [];
    state.listBill = listBill;
  },

  setListDRG(state, listDRG) {
    state.listDRG = [];
    state.listDRG = listDRG;
  },

  setBillClassifications(state, billClassifications) {
    state.billClassifications = [];
    state.billClassifications = billClassifications;
  },

  setListDepartmentResponsibilities(state, listDepartmentResponsibilities) {
    state.listDepartmentResponsibilities = [];
    state.listDepartmentResponsibilities = listDepartmentResponsibilities;
  },

  setListInsurancePolicies(state, listInsurancePolicies) {
    state.listInsurancePolicies = [];
    state.listInsurancePolicies = listInsurancePolicies;
  },

  setSaveAdditionalDates(state, payload) {
    switch (payload.flap) {
      case 1: {
        state.dataAdditional.from = payload.from ?? null;
        state.dataAdditional.to = payload.to ?? null;
        break;
      }
      case 2: {
        state.dataAdditional.unit = payload.unit ?? null;
        break;
      }
      default: {
        state.dataAdditional.from = payload.from;
        state.dataAdditional.to = payload.to;
        state.dataAdditional.changeSwitch = payload.changeSwitch;
      }
    }
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },

  setLoadInit(state, params) {
    const { isLoading, property } = params;
    state.loadInit[property] = isLoading;
  },

  setLastDateServices(state, lastDateServices) {
    state.lastDateServices = lastDateServices;
  },
};

const actions = {
  verifyRegisterClaim(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim/verify-register", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  registerClaim(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  checkEligibility(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim/check-eligibility", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  storeDraftClaim(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim/draft", data)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateDraftClaim(_, data) {
    let id = data.id;
    let update = data;
    delete update.id;

    return new Promise((resolve, reject) => {
      axios
        .put(`/claim/draft/${id}`, update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateClaim(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/claim/${data.id}`, data)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getClaims(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim", { params: payload })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getAllClaims(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-all-server", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getClaimById(_, claimId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/${claimId}`)
        .then((result) => {
          resolve(result.data);
          _.state.claim = result.data;
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getStatus({ commit }) {
    callSetLoadingInit(commit, true, "status");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-status")
        .then((result) => {
          commit("setStatus", result.data);
          callSetLoadingInit(commit, false, "status");
          resolve(result);
        })
        .catch((error) => {
          commit("setLoadInit", { isLoading: false, property: "status" });
          reject(error.response.data);
        });
    });
  },

  getBillingCompany({ commit }) {
    callSetLoadingInit(commit, true, "billings");

    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company`)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          callSetLoadingInit(commit, false, "billings");
          resolve(result.data);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "billings");
          reject(error.response.data);
        });
    });
  },

  getFormats({ commit }) {
    callSetLoadingInit(commit, true, "format");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-type-formats")
        .then((result) => {
          commit("setFormats", result.data);
          callSetLoadingInit(commit, false, "format");
          resolve(result);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "format");
          reject(error.response.data);
        });
    });
  },

  getCompanies({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(data)
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getFacilities({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/facility/get-list", { params: data })
        .then((result) => {
          commit("setFacilities", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBillClassifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `claim/bill-classifications/${data.facility_id}?billing_company_id=${data.billing_company_id}`
        )
        .then((result) => {
          commit("setBillClassifications", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getHealthProfessionals({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.authorization = true;

      axios
        .get("/health-professional/get-list", { params: data })
        .then((result) => {
          commit("setHealthProfessionals", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getHealthProfessionalsTable({ commit }, data) {
    delete data.authorization;

    return new Promise((resolve, reject) => {
      axios
        .get("/health-professional/get-list", { params: data })
        .then((result) => {
          commit("setHealthProfessionalsTable", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getPatients({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/patient/get-list", { params: data })
        .then((result) => {
          commit("setPatients", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getFields({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`claim/get-list-claim-field-informations?type=${payload}`)
        .then((result) => {
          commit("setFields", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getQualifiersHealthCare({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/claim/get-list-qualifier-by-field?type=health-care-institutional"
        )
        .then((result) => {
          commit("setQualifiers", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getQualifiers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const type =
        payload.format === 1
          ? "information-professional"
          : "information-institutional";

      axios
        .get(`/claim/get-list-qualifier-by-field?type=${type}&id=${payload.id}`)
        .then((result) => {
          commit("setQualifiers", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getRevCenters({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-rev-centers")
        .then((result) => {
          commit("setRevCenters", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getRevCodes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("claim/get-list-revenue-codes")
        .then((result) => {
          commit("setRevs", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getClaimServices({ commit }) {
    callSetLoadingInit(commit, true, "claimServices");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-claim-services")
        .then((result) => {
          commit("setClaimServices", result.data);
          callSetLoadingInit(commit, false, "claimServices");
          resolve(result.data);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "claimServices");
          reject(error.response.data);
        });
    });
  },

  searchModifiersByCode(_, code) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-list-modifiers/${code}`)
        .then((result) => {
          result.data = setName(result.data, "");
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  searchRevenueByCode(_, payload) {
    return new Promise((resolve, reject) => {
      const url = payload.procedureId
        ? `/claim/revenue-codes?search=${payload.search}&company_id=${payload.companyId}&procedure_id=${payload.procedureId}`
        : payload.search
        ? `/claim/revenue-codes?search=${payload.search}&company_id=${payload.companyId}`
        : `/claim/revenue-codes?company_id=${payload.companyId}`;

      axios
        .get(url)
        .then((result) => {
          result.data = setName(result.data, "procedures");
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  searchProcedureByCode(_, payload) {
    return new Promise((resolve, reject) => {
      const url = payload.revenueId
        ? `/claim/procedures/${payload.companyId}?search=${payload.search}&revenue_code_id=${payload.revenueId}&format=${payload.format}`
        : payload.search
        ? `/claim/procedures/${payload.companyId}?search=${payload.search}&format=${payload.format}`
        : `/claim/procedures/${payload.companyId}?format=${payload.format}`;

      axios
        .get(url)
        .then((result) => {
          result.data = setName(result.data, "revenue_codes");
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getDiagnosis({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/diagnosis")
        .then((result) => {
          commit("setDiagnosis", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPoliciesInsurance(_, patient_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/${patient_id}/get-policies`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getRecords(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/claim/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getPreviewClaim(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim/show-claim-preview", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  updateStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/change-status/${data.id}`, data.data_status)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  addCheckStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/add-check-status-claim/${data.id}`, data.check)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  saveNote(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/add-note-current-status/${data.id}`, data.form)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getSubStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim-sub-status/get-list-by-billing-company/${data}`)
        .then((result) => {
          commit("setSubStatus", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  subStatusSaveDraft(_, data) {
    return new Promise((resolve, reject) => {
      let url = data.billing_company_id
        ? `/claim-sub-status/get-list-by-billing-company/${data.status_id}/${data.billing_company_id}`
        : `/claim-sub-status/get-list-by-billing-company/${data.status_id}`;

      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListStatusAvailables({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim-sub-status/get-list-status?current_id=${data}`)

        .then((result) => {
          commit("setSubStatusTable", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListSubStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim-sub-status/get-list", { params: data })
        .then((result) => {
          commit("setSubStatusTable", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getCheckStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-check-status/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getListDepartmentResponsibilities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-list-department-responsibilities`)
        .then((result) => {
          commit("setListDepartmentResponsibilities", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListInsurancePolicies({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-list-insurance-policies/${id}`)
        .then((result) => {
          commit("setListInsurancePolicies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  saveCheckStatus(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/add-tracking-claim/${payload.id}`, payload.check)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getPlaceOfServices({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`claim/get-list-place-of-services?facility_id=${data}`)
        .then((result) => {
          commit("setPlaceOfServices", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getConditionCodes(_, code) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-list-condition-codes?search=${code}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getListAdmissionType({ commit }) {
    callSetLoadingInit(commit, true, "addType");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-admission-types")
        .then((result) => {
          commit("setListAdmissionType", result.data);
          callSetLoadingInit(commit, false, "addType");
          resolve(result);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "addType");
          reject(error.response.data);
        });
    });
  },

  getListAdmissionSources({ commit }) {
    callSetLoadingInit(commit, true, "addSources");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-admission-sources")
        .then((result) => {
          commit("setListAdmissionSources", result.data);
          callSetLoadingInit(commit, false, "addSources");
          resolve(result);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "addSources");
          reject(error.response.data);
        });
    });
  },

  getListPatientStatuses({ commit }) {
    callSetLoadingInit(commit, true, "patStatus");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-patient-statuses")
        .then((result) => {
          commit("setListPatientStatuses", result.data);
          callSetLoadingInit(commit, false, "patStatus");
          resolve(result);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "patStatus");
          reject(error.response.data);
        });
    });
  },

  getListBill({ commit }) {
    callSetLoadingInit(commit, true, "listBill");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-bill-classifications")
        .then((result) => {
          commit("setListBill", result.data);
          callSetLoadingInit(commit, false, "listBill");
          resolve(result);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "listBill");
          reject(error.response.data);
        });
    });
  },

  getListDRG({ commit }) {
    callSetLoadingInit(commit, true, "listDRG");

    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-diagnosis-related-groups")
        .then((result) => {
          commit("setListDRG", result.data);
          callSetLoadingInit(commit, false, "listDRG");
          resolve(result);
        })
        .catch((error) => {
          callSetLoadingInit(commit, false, "listDRG");
          reject(error.response.data);
        });
    });
  },

  getPreviewAllClaims(_, payload) {
    return new Promise((resolve, reject) => {
      const url = `/claim/get-all-server?rule=${payload.id}&filter=type=${payload.type}`;

      axios
        .get(url, { params: payload.params })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  saveAdditionalDates({ commit }, payload) {
    commit("setSaveAdditionalDates", payload);
  },

  saveLastDateServices({ commit }, payload) {
    commit("setLastDateServices", payload);
  },
};

function callSetLoadingInit(commit, isLoading, property) {
  commit("setLoadInit", { isLoading, property });
}

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
