import axios from "../../plugins/axios";

const state = () => ({
  house: {},
  items: [],
  billingCompanies: [],
  transmissions: [],
  orgTypes: [],
  pivoteRequest: null,
});

const mutations = {
  setOrgType(state, orgTypes) {
    state.orgTypes = orgTypes;
  },
  setTransmission(state, transmissions) {
    state.transmissions = transmissions;
  },
  /**
   * @function setBilligCompanies
   * @description set BilligCompanies.
   * @param {Array} billingCompanies Billing companies list.
   */
  setBilligCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
  },
  /**
   * @function setClearingHouses
   * @description Set clearingHouses.
   * @param {Array} clearingHouses Clearing Houses list.
   */
  setClearingHouses(state, clearingHouses) {
    state.items = [];

    clearingHouses.map((clearingHouse) => {
      state.items.push({
        id: clearingHouse.id,
        name: clearingHouse.name,
        code: clearingHouse.code,
        status: clearingHouse.status,
        email: clearingHouse.contacts[0]?.email ?? "",
      });
    });
  },

  /**
   * @function addClearingHouse
   * @description Add clearingHouse to list.
   * @param {Object} clearingHouse Clearing Houses data.
   */
  addClearingHouse(state, clearingHouse) {
    state.items.push({
      id: clearingHouse.id,
      name: clearingHouse.name,
      code: clearingHouse.code,
      status: clearingHouse.status,
      email: clearingHouse.contact.email,
    });
  },

  /**
   * @function updateClearingHouse
   * @description Update a Clearing House.
   * @param {Object} data Clearing House.
   */
  updateClearingHouse(state, data) {
    state.items.map((item) => {
      if (item.id === data.id) {
        item.name = data.update.name;
        item.email = data.update.contact.email;
      }

      return item;
    });
  },

  /**
   * @function updateStatusClearingHouse
   * @description Update status a Clearing House.
   * @param {Number} data Clearing House ID.
   */
  updateStatusClearingHouse(state, data) {
    state.items.map((item) => {
      if (item.id === data) {
        item.status = !item.status;
      }

      return item;
    });
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  /**
   * @function getClearingHouses
   * @description Petition for get all facilities.
   */
  getClearingHouses({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/clearing-house")
        .then((result) => {
          commit("setClearingHouses", result.data);
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  searchClearingHouse(_, name) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/clearing-house/get-by-name/${name}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  /**
   * @function storeClearingHouse
   * @description Store Clearing House.
   * @param {Object} data Clearing House data.
   */
  storeClearingHouse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/clearing-house", data)
        .then((result) => {
          let clearingHouse = data;

          clearingHouse.id = result.data.id;
          clearingHouse.code = result.data.code;
          clearingHouse.status = result.data.status;

          commit("addClearingHouse", clearingHouse);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  /**
   * @function updateClearingHouse
   * @description Update a Clearing House.
   * @param {Object} data Clearing House data to update.
   */
  updateClearingHouse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/clearing-house/${data.id}`, data.update)
        .then((result) => {
          commit("updateClearingHouse", data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  /**
   * @function updateStatusClearingHouse
   * @description Update status of a Clearing House.
   * @param {Object} data Clearing House data status update.
   */
  updateStatusClearingHouse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/clearing-house/${data.id}`, data)
        .then((result) => {
          commit("updateStatusClearingHouse", data.id);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  /**
   * @function getClearingHouse
   * @description Get all Clearing House.
   */
  getClearingHouse() {
    return new Promise((resolve, reject) => {
      axios
        .get("/clearing-house")
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  /**
   * @function getClearingHouseById
   * @description Get a Clearing House by ID.
   * @param {Number} data Clearing House ID.
   */
  getClearingHouseById(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/clearing-house/${data}`)
        .then((result) => {
          resolve(result.data);
          _.state.house = result.data;
        })
        .catch((error) => {
          reject(error.response.data);
          console.error("error", error);
        });
    });
  },

  /**
   * @function addToBillingCompany
   * @description Add Clearing House to Billing Company.
   * @param {Object} data Clearing House data.
   */
  addToBillingCompany(clearingHouseId) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/clearing-house/add-to-billing-company/${clearingHouseId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/billing-company")
        .then((result) => {
          commit("setBilligCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
          console.error("error", error);
        });
    });
  },
  getListT({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/clearing-house/get-list-transmission-formats")
        .then((result) => {
          commit("setTransmission", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
          console.error("error", error);
        });
    });
  },
  getListOrgType({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/clearing-house/get-list-org-types")
        .then((result) => {
          commit("setOrgType", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
          console.error("error", error);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
